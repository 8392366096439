/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: `Benjamin Moore, Benjamin Moore Paint, Paint store Thornbury, Annie Sloan, Chalk Paint, Art Supplies, Decorative Finishes`,
        },
      ].concat(meta)}
    >
      <script src="https://kit.fontawesome.com/efadfda292.js" crossorigin="anonymous"></script>
      <link rel="stylesheet" href="https://unpkg.com/purecss@2.0.3/build/pure-min.css" integrity="sha384-cg6SkqEOCV1NbJoCu11+bm0NvBRc8IYLRGXkmNrqUBfTjmMYwNKPWBTIKyw9mHNJ" crossorigin="anonymous"></link>
      <script type="application/ld+json">
        {"{\n"+
          '"@context": "http://schema.org",\n'+
          '"@id": "0fba83a4-561b-4b56-a182-d6f0fd8031d0",\n'+
          '"@type": "LocalBusiness",\n'+
          '"name": "Birch & Benjamin",\n'+
          '"image": ["/storefront.centre.jpg", "/logo.png", "/y.b&bfront.JPG"],\n'+
          '"telephone": "519-599-2337",\n'+
          '"email": "info@birchandbenjamin.com",\n'+
          '"address": {\n'+
          '"@type": "PostalAddress",\n'+
            '"streetAddress": "15 Bruce Street South, Thornbury, ON N0H 2P0",\n'+
            '"addressLocality": "Thornbury",\n'+
            '"addressRegion": "Ontario",\n'+
            '"addressCountry": "Canada",\n'+
            '"postalCode": "N0H 2P0"\n'+
            '},\n'+
          '"openingHoursSpecification": {\n'+
          '"@type": "OpeningHoursSpecification",\n'+
            '"dayOfWeek": {\n'+
            '"@type": "DayOfWeek",\n'+
              '"name": "Mo 07:30 - 17:30, Tu 07:30 - 17:30, We 07:30 - 17:30, Th 07:30 - 17:30, Fr 07:30 - 17:30, Sa 09:00 - 16:00, Su Closed"\n'+
              '}\n'+
            '},\n'+
            '"url": "https://www.birchandbenjamin.com/contact-us"\n'+
      '}'}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
