export const container = {
    out: { opacity: 0 },
    in: {
        opacity: 1,
        transition: {
            staggerChildren: 0.15
        }
    }
}

export const containerFast = {
    out: { opacity: 0 },
    in: {
        opacity: 1,
        transition: {
            staggerChildren: 0.02
        }
    }
}

export const fadeDownRL = {
    out: {
        opacity: 0,
        y: -200,
        rotate: 95
    },
    in: {
        opacity: 1,
        y: 0,
        rotate: 0
    }
}

export const fadeDownRR = {
    out: {
        opacity: 0,
        y: -200,
        rotate: -95
    },
    in: {
        opacity: 1,
        y: 0,
        rotate: 0
    }
}

export const fadeDown = {
    out: {
        opacity: 0,
        y: -200
    },
    in: {
        opacity: 1,
        y: 0
    }
}

export const fadeUp = {
    out: {
        opacity: 0,
        y: 200
    },
    in: {
        opacity: 1,
        y: 0
    }
}

export const socialScaleUp = {
    out: {
        opacity: 0,
        y: 200,
        scale: 0,
    },
    in: {
        opacity: 1,
        y: 0,
        scale: 1
    }
}

export const scaleUp = {
    scale: 1.2,
}

export const scaleUpRR = {
    scale: 1.2,
    rotate: -5,
}