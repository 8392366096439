/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
//import { useStaticQuery, graphql } from "gatsby"
import { Link } from 'gatsby'
import "./layout.scss"
import { fadeDownRR } from "../animations"
import { motion } from 'framer-motion'

const Layout = ({ className, children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <div className="layout-container">
        <div className="border-logo-container">
          <Link to="/"><motion.img variants={fadeDownRR} animate="in" initial="out" src="/logo-dark.png" alt="Logo" className="border-logo" /></Link>
        </div>
        <main className={className}>{children}</main>
      </div>
      <footer>
        <div className="footer left">
          <p>Brick & Mortar: </p>
          <p>15 Bruce Street South,</p>
          <p>Downtown Thornbury,</p>
          <p>Ontario</p>
          <a href="tel:5195992337">519.599.2337</a>
        </div>
        <div className="footer right">
          <p>Store Open:</p>
          <div className="time-row">
            <p>Monday - Friday</p>
            <p>7:30am - 5:30pm</p>
          </div>
          <div className="time-row">
            <p>Saturdays</p>
            <p>9:00am - 4:00pm</p>
          </div>
          <div className="time-row">
            <p>Sundays</p>
            <p>Closed</p>
          </div>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
